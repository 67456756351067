.breadcrum_area{
    background-image: url("../../img/header2x.jpg");
    background-size: cover;
    position: relative;
    z-index: 1 !important;
    overflow: hidden;
    background-position: top ;
    padding-top: 10%;
  }

  .mainBlock{
    margin-top: 2%;
  }